const EditIcons = () => {
  return (
    <svg
      className="fill-silver hover:fill-black dark:hover:fill-white cursor-pointer"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      xmlns="https://www.w3.org/2000/svg"
    >
      <rect
        x="3.81787"
        y="3.81787"
        width="2.54545"
        height="2.54545"
        transform="rotate(180 3.81787 3.81787)"
      ></rect>
      <rect
        x="3.81787"
        y="2.96973"
        width="1.69697"
        height="1.69697"
        transform="rotate(180 3.81787 2.96973)"
      ></rect>
      <rect
        x="3.81787"
        y="12.7266"
        width="2.54545"
        height="2.54545"
        transform="rotate(180 3.81787 12.7266)"
      ></rect>
      <rect
        x="3.81787"
        y="11.8779"
        width="1.69697"
        height="1.69697"
        transform="rotate(180 3.81787 11.8779)"
      ></rect>
      <rect
        x="12.7271"
        y="3.81787"
        width="2.54545"
        height="2.54545"
        transform="rotate(180 12.7271 3.81787)"
      ></rect>
      <rect
        x="12.7271"
        y="2.96973"
        width="1.69697"
        height="1.69697"
        transform="rotate(180 12.7271 2.96973)"
      ></rect>
      <rect
        x="12.7271"
        y="12.7266"
        width="2.54545"
        height="2.54545"
        transform="rotate(180 12.7271 12.7266)"
      ></rect>
      <rect
        x="12.7271"
        y="11.8779"
        width="1.69697"
        height="1.69697"
        transform="rotate(180 12.7271 11.8779)"
      ></rect>
      <rect
        x="4.45459"
        y="4.45361"
        width="1.90909"
        height="1.90909"
        transform="rotate(180 4.45459 4.45361)"
      ></rect>
      <rect
        x="4.45459"
        y="3.81738"
        width="1.27273"
        height="1.27273"
        transform="rotate(180 4.45459 3.81738)"
      ></rect>
      <rect
        x="4.45459"
        y="3.81738"
        width="1.27273"
        height="1.27273"
        transform="rotate(180 4.45459 3.81738)"
      ></rect>
      <rect
        x="6.57593"
        y="2.54541"
        width="2.54545"
        height="1.69697"
        transform="rotate(-90 6.57593 2.54541)"
      ></rect>
      <rect
        x="5.72729"
        y="2.54541"
        width="2.54545"
        height="1.69697"
        transform="rotate(-90 5.72729 2.54541)"
      ></rect>
      <rect
        x="6.36353"
        y="11.4536"
        width="1.90909"
        height="5.09091"
        transform="rotate(-90 6.36353 11.4536)"
      ></rect>
      <rect
        x="3.81812"
        y="11.4536"
        width="1.90909"
        height="5.09091"
        transform="rotate(-90 3.81812 11.4536)"
      ></rect>
      <rect
        x="5.93945"
        y="4.45361"
        width="1.90909"
        height="4.24242"
        transform="rotate(-90 5.93945 4.45361)"
      ></rect>
      <rect
        x="3.81812"
        y="4.45361"
        width="1.90909"
        height="4.24242"
        transform="rotate(-90 3.81812 4.45361)"
      ></rect>
      <rect x="2.54541" y="6.15137" width="1.90909" height="4.66667"></rect>
      <rect x="2.54541" y="3.81787" width="1.90909" height="4.66667"></rect>
      <rect x="4.45459" y="4.87842" width="1.27273" height="0.848485"></rect>
      <rect x="4.45459" y="4.4541" width="1.27273" height="0.848485"></rect>
      <rect x="8.27295" y="4.87842" width="1.27273" height="0.848485"></rect>
      <rect x="8.27295" y="4.4541" width="1.27273" height="0.848485"></rect>
      <rect x="8.27295" y="8.69678" width="1.27273" height="0.848485"></rect>
      <rect x="8.27295" y="8.27246" width="1.27273" height="0.848485"></rect>
      <rect x="4.45459" y="8.69678" width="1.27273" height="0.848485"></rect>
      <rect x="4.45459" y="8.27246" width="1.27273" height="0.848485"></rect>
      <rect x="9.54541" y="5.93945" width="1.90909" height="4.24242"></rect>
      <rect x="9.54541" y="3.81787" width="1.90909" height="4.24242"></rect>
      <rect
        x="7.42407"
        y="11.4541"
        width="2.54545"
        height="1.69697"
        transform="rotate(90 7.42407 11.4541)"
      ></rect>
      <rect
        x="8.27271"
        y="11.4541"
        width="2.54545"
        height="1.69697"
        transform="rotate(90 8.27271 11.4541)"
      ></rect>
      <rect y="6.36377" width="2.54545" height="1.27273"></rect>
      <rect y="5.72705" width="2.54545" height="0.636364"></rect>
      <rect
        x="13.3635"
        y="6.99951"
        width="2.54545"
        height="1.27273"
        transform="rotate(-180 13.3635 6.99951)"
      ></rect>
      <rect
        x="13.3635"
        y="7.63574"
        width="2.54545"
        height="0.636364"
        transform="rotate(-180 13.3635 7.63574)"
      ></rect>
      <rect x="11.4546" y="6.57568" width="2.54545" height="1.69697"></rect>
      <rect x="11.4546" y="5.72705" width="2.54545" height="1.69697"></rect>
      <rect
        x="2.54541"
        y="7.42383"
        width="2.54545"
        height="1.69697"
        transform="rotate(-180 2.54541 7.42383)"
      ></rect>
      <rect
        x="2.54541"
        y="8.27246"
        width="2.54545"
        height="1.69697"
        transform="rotate(-180 2.54541 8.27246)"
      ></rect>
    </svg>
  );
};

export default EditIcons;
